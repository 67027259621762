import React from "react"
import { navigate, Link } from "gatsby"
import { AssetHeaderBackChevron } from "../components/assets"

function HeaderButton(props){
  const {action} = props
  switch(action) {
    case 'resume':
      return (
        <Link to="/resume/" className="actionItem button medium primary" activeClassName="selected">
          <div className="buttonTitle">View my CV</div>
        </Link>
      )
    default:
      return (
        <Link to="/hire/" className="actionItem button medium primary" activeClassName="selected">
          <div className="buttonTitle">Hire Phil</div>
        </Link>
      )
  }
}

export default function Header(props) {
  const { headerType } = props
  switch(headerType) {
    case 'detail':
      return (
        <header className="mercuryHeader detailNav">
          <div className="headerContent">
            <div className="navigationControl">
              <button onClick={() => navigate(-1)} onKeyDown={() => navigate(-1)} className="backButton button medium secondary">
                <AssetHeaderBackChevron className="buttonIcon"/>
                <div className="buttonTitle">Back</div>
              </button>

              <Link to="/" className="navigationItem itemHome" activeClassName="selected">
                <div className="navigationTitle">Phil Amour</div>
              </Link>
            </div>

            <div className="rightActions">
              <Link to="/hire/" className="actionItem button medium primary" activeClassName="selected">
                <div className="buttonTitle">Hire Phil</div>
              </Link>
            </div>
          </div>
        </header>
      )
    case 'subview':
      return (
        <header className="mercuryHeader detailNav">
          <div className="headerContent">
            <div className="navigationControl">
              <Link to="/" className="backButton button medium secondary">
                <AssetHeaderBackChevron className="buttonIcon"/>
                <div className="buttonTitle">Phil Amour</div>
              </Link>
            </div>

            <div className="rightActions">
              <Link to="/hire/" className="actionItem button medium primary" activeClassName="selected">
                <div className="buttonTitle">Hire Phil</div>
              </Link>
            </div>
          </div>
        </header>
      )
    default:
      return (
        <header className="mercuryHeader">
          <div className="headerContent">
            <div className="navigationControl">
              <Link to="/" className="navigationItem itemHome" activeClassName="selected">
                <div className="navigationTitle">Phil Amour</div>
              </Link>
            </div>

            <div className="rightActions">
              <HeaderButton action={props.action} />
            </div>
          </div>
        </header>
      )
  }
}
