import React from "react"
import { Link } from "gatsby"

export default function Footer(props) {
  return <footer className="mercuryFooter" id="footer">
    <div className="footerContent">
      <div className="footerSection sectionLinks">
        <div className="linksContent">
          <p className="copyright">© {new Date().getFullYear()}, Phil Amour. All Rights Reserved.</p>

          <div className="footerActions">
            {/*<a href="https://twitter.com/philipamour" target="_blank" rel="noreferrer" className="actionItem">Twitter</a>*/}
            <a rel="me" href="https://mastodon.design/@phil" className="actionItem">Mastodon</a>
            <a href="https://dribbble.com/philipamour" target="_blank" rel="noreferrer" className="actionItem">Dribbble</a>
            <a href="https://www.linkedin.com/in/philipamour/" target="_blank" rel="noreferrer" className="actionItem">LinkedIn</a>
            <a href="https://www.instagram.com/philamour/" target="_blank" rel="noreferrer" className="actionItem">Instagram</a>
            <Link to="/case-studies/" className="actionItem">Case Studies</Link>
            <Link to="/hire/" className="actionItem">Contact</Link>
          </div>
        </div>
      </div>
    </div>
  </footer>
}
