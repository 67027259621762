import * as React from "react"

function AssetHeaderBackChevron(props) {
  return (
    <svg
      width={15}
      height={10}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 4.597c0-.204.078-.41.234-.566L4.031.234a.8.8 0 111.132 1.132L2.73 3.797h10.707a.8.8 0 110 1.6H2.731L5.163 7.83A.8.8 0 014.03 8.96L.234 5.163A.797.797 0 010 4.597z"
        fill="#000"
      />
    </svg>
  );
}

function AssetInputPopUpChevron(props) {
  return (
    <svg
      width={8}
      height={12}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.767 3.384a.79.79 0 01.162-.24L3.72.295C3.911.098 4.125 0 4.36 0a.827.827 0 01.333.073c.103.047.205.12.306.221l2.784 2.85A.703.703 0 018 3.668c0 .142-.035.27-.104.387a.778.778 0 01-.275.274.74.74 0 01-.936-.147L4.364 1.776 2.036 4.181a.764.764 0 01-.565.252.723.723 0 01-.38-.105.823.823 0 01-.274-.274.76.76 0 01-.1-.387c0-.103.017-.197.05-.283zm3.926 8.546a.784.784 0 01-.333.07c-.232 0-.446-.097-.642-.29L.93 8.86a.79.79 0 01-.163-.24.764.764 0 01-.05-.283.769.769 0 01.375-.661.723.723 0 01.379-.105c.211 0 .4.084.565.252l2.328 2.409 2.32-2.41a.748.748 0 011.212.132.73.73 0 01.104.383c0 .206-.072.38-.217.522L5 11.71c-.101.101-.203.175-.306.221z"
        fill="#000"
      />
    </svg>
  );
}

function AssetHintArrowDown(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="18"
      fill="none"
      viewBox="0 0 14 18"
      {...props}
    >
      <path
        fill="#000"
        fillRule="evenodd"
        d="M7.528 17.036a.75.75 0 01-1.06 0L.84 11.406a.75.75 0 011.06-1.06L6.254 14.7V1.508a.75.75 0 011.5 0v13.18l4.34-4.343a.752.752 0 011.28.531.749.749 0 01-.22.53l-5.626 5.63z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

function AssetBulletArrow(props) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.53 9.19l-4.2-4.016a.63.63 0 00-.957.81l1.921 2.857-9.577.532a.627.627 0 000 1.254l9.577.532-1.92 2.857a.63.63 0 00.955.81l4.2-4.017a1.122 1.122 0 000-1.618"
        fill="#000"
      />
    </svg>
  );
}

function AssetBulletCircledArrow(props) {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.28 9.53l-3 3a.749.749 0 01-1.223-.817.75.75 0 01.163-.243l1.72-1.72H5.25a.75.75 0 010-1.5h5.69L9.22 6.53a.75.75 0 111.06-1.06l3 3a.749.749 0 010 1.06M9 0a9 9 0 100 18A9 9 0 009 0z"
        fill="#000"
      />
    </svg>
  );
}

function AssetBulletCheck(props) {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.636 6.397l-3.75 6a.751.751 0 01-1.166.133l-2.25-2.25a.75.75 0 011.06-1.06l1.583 1.583 3.251-5.2a.75.75 0 111.272.795M9 0a9 9 0 100 18A9 9 0 009 0"
        fill="#000"
      />
    </svg>
  );
}

export { AssetHeaderBackChevron, AssetInputPopUpChevron, AssetHintArrowDown, AssetBulletArrow, AssetBulletCircledArrow, AssetBulletCheck }
